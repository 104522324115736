<template>
    <div :class="{'select-gender':true, 'pa-2':true, 'active': active }" @click="$emit('click')">
        <app-icon :icon="icon" class="gender-icon" size="19"/>
        <p class="gender-text">{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: "SelectGender",
    props: {
        icon: {
            type: String
        },
        text: {
            type: String
        },
        active: Boolean
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';
.select-gender.active {
    box-shadow: 0px 2px 17px 0px #fe6c11;;
    background: white;
    border: 1px solid #FE4811;
    color:#FD5B13;
    & .gender-icon {
        color:#FD5B13 !important;
    }


}

.select-gender {
    border: 1px solid #EDEDED;
    height: 60px;
    border-radius: 8px;
    text-align: center;
    transition: 0.4s;
}
.gender-text{
    font-size: 0.8em;
}
</style>
