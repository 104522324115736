<template>
    <v-container fluid class="pa-0">
        <v-row>
            <v-col class="pb-0">
                <div class="block-title">
                    {{title}}
                </div>
            </v-col>

        </v-row>
        <v-row>
            <v-col>
                <slot/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "FilterHomeBlock",
    props: {
        title: {
            type: String
        }
    }
}
</script>

<style scoped>

</style>
