<template>
    <div class="home__gallery-item">
        <profile-image
            :has-not-watched-stories="item.hasNotWatchedStories"
            :size="customSize"
            :font-size="18"
            :is-exist-story="!!item.has_stories"
            :img="item.image"
            :username="item.name"
            border="4"
            @click="$emit('click', { id })"
            :include-stories="false"
        />
        <div class="mt-1 d-flex align-center justify-center">
            <span
                class="home__gallery-item-name caption d-inline-block text-truncate"
            >
                <router-link
                    :to="{
                        name: 'otherUser.profile.viewSlug',
                        params: { slug: item.slug, from: $route.name },
                    }"
                    >{{ item.name }}</router-link
                >
            </span>
            <verified-icon
                v-if="(star && item.subscribed) || this.item.isVerify"
                :height="12" :width="12" class="ml-1" />
        </div>
    </div>
</template>

<script>
import ProfileImage from "@/components/app/profile/ProfileImage";
import { mapActions, mapState } from "vuex";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";

export default {
    name: "HomeGalleryItem",
    components: { ProfileImage, VerifiedIcon },
    computed: {
        ...mapActions([
            "setOtherUser",
            "setOtherUserBySlug",
            "sendUserTip",
            "subscribe",
            "unsubscribe",
            "sendBlockedUser",
            "sendUnblockedUser",
            "sendReportUser",
            "sendTipMessage",
            "buyDirectMessage",
        ]),
        ...mapState({
            profileImage: (state) => state.otherUser.user.profileImage,
            user: (state) => state.otherUser.user,
        }),
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        star: {
            type: Boolean,
            default: true,
        },
        id: {
            type: [String, Number],
            //default: true
        },
        customSize: {
            type: Number,
            default: 78
        }
    },
};
</script>

<style lang="scss">
@import "../../../sass/modules/variables";

.home__gallery-item {
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &-image {
        display: flex;
        align-items: center;
        width: 94px;
        height: 94px;

        position: relative;
        padding: 4px;
        box-sizing: border-box;

        $border: 4px;
        background: #000;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 500px;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            border-radius: inherit;
            background: $accent-background;
        }
    }

    &-name {
        display: inline-block;
        max-width: 79px;
    }
}
</style>
