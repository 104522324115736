<template>
    <div>
        <home-section-title :text="title"/>
        <v-container class="pt-0 px-4 mb-6">
            <v-slide-x-reverse-transition>
                <v-row>
                    <v-col class="home__discover-item" v-for="(item, i) in orderedItems"
                           :key="i"
                           cols="4">
                        <div class="d-flex justify-center">
                            <home-gallery-item
                                :item="item"
                                :id="item.id"
                                :star="false"
                                @click="handleClickGalleryItem(i)"
                                class="discover-home-gallery-items"
                                :custom-size="86"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-slide-x-reverse-transition>
        </v-container>
    </div>
</template>

<script>
import HomeSectionTitle from '@/components/app/home/HomeSectionTitle';
import HomeGalleryItem from '@/components/app/home/HomeGalleryItem';
import _ from "lodash";

export default {
    name: "HomeDiscover",
    components: {
        HomeSectionTitle,
        HomeGalleryItem
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        items: {
            type: [Array, Object],
            default: () => []
        }
    },
    computed: {
        orderedItems() {
            return _.orderBy(this.items, 'order', 'desc')
        },
        orderedItemsHasStories() {
            return this.orderedItems.filter((item) => {
                return item.has_stories > 0
            })
        },
    },
    methods: {
        handleClickGalleryItem(index) {
            this.$emit('click',{
                items: this.orderedItemsHasStories,
                index: index,
            })
        },
    }
}
</script>

<style lang="scss">
.home__discover-item {
  z-index: 1;

  div {
    .discover-home-gallery-items {
        margin-left: 5px;
    }
  }
}
</style>
