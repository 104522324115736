<template>
    <div>
        <home-section-title :text="title" />
        <v-sheet color="transparent" class="pt-1 px-2 container" height="110">
            <v-slide-x-reverse-transition>
                <swiper class="swiper justify-lg-center justify-md-center" :options="swiperOption">
                    <swiper-slide v-for="(item, i) in orderedItems" :key="i" >
                        <home-gallery-item :item="item" :id="item.id" @click="handleClickGalleryItem(i)"/>
                    </swiper-slide>
                </swiper>
            </v-slide-x-reverse-transition>
        </v-sheet>
    </div>
</template>

<script>
import HomeSectionTitle from '@/components/app/home/HomeSectionTitle';
import HomeGalleryItem from '@/components/app/home/HomeGalleryItem';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import _ from "lodash";

export default {
    name: "HomeSwiper",
    components: {
        HomeSectionTitle,
        HomeGalleryItem,
        Swiper,
        SwiperSlide
    },
    props: {
        title: {
            type: String,
            required: true
        },
        items: {
            type: [Array, Object],
            default: () => []
        }
    },
    computed: {
        orderedItems() {
            return _.orderBy(this.items, 'order', 'desc')
        },
        orderedItemsHasStories() {
            return this.orderedItems.filter((item) => {
                return item.has_stories > 0
            })
        },
    },
    data: () => ({
        swiperOption: {
            slidesPerView: 'auto',
            freeMode: true,
            cssMode: true,
        },
    }),
    methods: {
        handleClickGalleryItem(index) {
            this.$emit('click',{
                items: this.orderedItemsHasStories,
                index: index,
            })
        },
    }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings';

.swiper {
    -webkit-transform: translateZ(0);

    &-slide {
        display: flex;
        justify-content: center;
        width: auto;
        margin-left: map-deep-get($utilities, 'margin-left', values, 4);
        -webkit-transform: translateZ(0);

        @media #{map-get($display-breakpoints, 'sm-and-down')} {
            width: 33.33%;
            margin-left: map-deep-get($utilities, 'margin-left', values, 1);
        }

        &:last-child {
            margin-right: map-deep-get($utilities, 'margin-right', values, 4);

            @media #{map-get($display-breakpoints, 'sm-and-down')} {
                margin-right: map-deep-get($utilities, 'margin-right', values, 1);
            }
        }
    }
}
</style>
