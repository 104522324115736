<template>
    <v-container class="px-2 py-2">
        <div class="overflow-hidden text-center">
            <span class="home__section-title text-uppercase font-weight-medium">
                {{ text }}
            </span>
        </div>
    </v-container>
</template>

<script>
export default {
  name: "HomeSectionTitle",
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
//@import '~vuetify/src/styles/settings';
@import '../../../sass/modules/variables';

.home__section-title {
  display: inline-block;
  position: relative;
  font-size: 17px;
  letter-spacing: .05em;

  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 1px);
    width: 50vw;
    height: 1px;
    background: $text-base;
    opacity: .4;
  }

  &::before {
    right: calc(100% + #{$spacer * 2});
  }

  &::after {
    left: calc(100% + #{$spacer * 2});
  }
}
</style>
