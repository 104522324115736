<template>
    <Dialog :scrollable="true" @close="$emit('close')">
        <v-container class="pt-0 pl-3 pr-3">
            <v-row>
                <v-col class="title-filter text-center">{{
                    $t("dialogs.filters")
                }}</v-col>
            </v-row>
            <v-row>
                <filter-home-block :title="$t('dialogs.section')">
                    <div class="SectionControl-wrapper">
                        <div>
                            <v-checkbox
                                dense
                                value="subscribed"
                                color="white"
                                class="pt-0 mt-0"
                                v-model="model.sections"
                                :label="$t('dialogs.subscribed')"
                            />
                        </div>
                        <div>
                            <v-checkbox
                                dense
                                value="following"
                                color="white"
                                class="pt-0 mt-0"
                                v-model="model.sections"
                                :label="$t('dialogs.following')"
                            />
                        </div>
                        <div>
                            <v-checkbox
                                dense
                                value="discover"
                                color="white"
                                class="pt-0 mt-0"
                                v-model="model.sections"
                                :label="$t('dialogs.discover')"
                            />
                        </div>
                    </div>
                </filter-home-block>
            </v-row>
            <v-row>
                <filter-home-block :title="$t('dialogs.age')">
                    <v-range-slider
                        dense
                        hide-details
                        min="18"
                        max="100"
                        color="white"
                        persistent-hint
                        v-model="model.range"
                        track-color="gray"
                        track-fill-color="white"
                        thumb-color="#FF5600"
                        thumb-label="always"
                        class="pt-6"
                        white-text=""
                    />
                    <v-row>
                        <v-col class="pt-0 text-start"> 18 </v-col>
                        <v-spacer />
                        <v-col class="pt-0 text-end"> 100 </v-col>
                    </v-row>
                </filter-home-block>
            </v-row>
            <v-row>
                <filter-home-block :title="$t('dialogs.sex')">
                    <v-row>
                        <v-col
                            v-for="(item, i) in genders"
                            :key="i"
                            class="pt-0"
                        >
                            <select-gender
                                :icon="item.icon"
                                :text="item.gender"
                                :active="
                                    model.activeGenders &&
                                    model.activeGenders.includes(item.icon)
                                "
                                @click="toggleGenders(item.icon)"
                            />
                        </v-col>
                    </v-row>
                </filter-home-block>
            </v-row>
            <v-row>
                <v-expansion-panels v-model="model.panel" style="border: 1px solid #ddd; border-radius: 4px;">
                    <v-expansion-panel
                        class="expanded-panels"
                        @change="changePanel"
                    >
                        <v-expansion-panel-header
                            class="expand-block-header__before-post pl-3 pt-0 pb-0"
                            expand-icon="empty-icon"
                        >
                            <v-row cols="12" align="baseline">
                                <v-col cols="1">
                                    <v-icon
                                        >mdi-chevron-{{
                                            toggle === false ? "down" : "up"
                                        }}</v-icon
                                    >
                                </v-col>
                                <v-col cols="7">
                                    {{ $t("dialogs.tags") }}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            fluid
                            fill-height
                            class="expand-block-body__before-post"
                        >
                            <div style="overflow-x: scroll; max-height: 300px">
                                <div
                                    v-for="group in getFilteredTagsByGender()"
                                    :key="group.title"
                                >

                                    <v-list-item
                                        v-for="tag in group.items"
                                        :key="tag.id"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title
                                                class="tags-text"
                                            >
                                                {{ tag.title }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox
                                                dense
                                                hide-details
                                                :value="tag.id"
                                                color="white"
                                                class="pt-0 mt-0"
                                                v-model="model.tags"
                                            />
                                        </v-list-item-action>
                                    </v-list-item>
                                </div>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
            <v-row class="mt-4">
                <v-expansion-panels v-model="model.categoriesPanel" style="border: 1px solid #ddd; border-radius: 4px;">
                    <v-expansion-panel
                        class="expanded-panels"
                        @change="changeCategoriesPanel"
                    >
                        <v-expansion-panel-header
                            class="expand-block-header__before-post pl-3 pt-0 pb-0"
                            expand-icon="empty-icon">
                            <v-row cols="12" align="baseline">
                                <v-col cols="1">
                                    <v-icon
                                        >mdi-chevron-{{
                                            categoriesToggle === false ? "down" : "up"
                                        }}</v-icon
                                    >
                                </v-col>
                                <v-col cols="7">
                                    {{ $t("pages.profile.info.categories") }}
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            fluid
                            fill-height
                            class="expand-block-body__before-post"
                        >
                            <div style="overflow-x: scroll; max-height: 300px">                                
                                <user-categories 
                                    :is-filter-page="true"
                                    :initial-selected-filter-ids = "model.categories"
                                    @update:selectedCategoryIds="handleSelectedCategories"
                                />
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
            <v-row>
                
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        outlined
                        block
                        class="mt-9 pa-2"
                        @click="handleResetFilter"
                    >
                        Reset
                    </v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        outlined
                        block
                        class="mt-9 pa-2"
                        @click="handleClickSave"
                    >
                        {{ $t("dialogs.save") }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <inform-dialog
            :text="dialogText"
            v-if="showErrorDialog"
            @close="showErrorDialog = false"
        />
    </Dialog>
</template>

<script>
import Dialog from "@/components/app/form/Dialog";
import FilterHomeBlock from "@/components/app/home/FilterHomeBlock";
import SelectGender from "@/components/app/home/SelectGender";
import { mapActions, mapMutations, mapState } from "vuex";
import Checkboxes from "@/components/app/posting/BeforePostingBlock.vue";
import InformDialog from "@/components/app/dialogs/InformDialog";
import UserCategories from "@/components/app/profile/UserCategories";

export default {
    name: "FilterHome",
    data: () => ({
        toggle: false,
        categoriesToggle: false,
        showErrorDialog: false,
        model: () => {
            return {};
        },
    }),
    computed: {
        genders() {
            return [
                { gender: this.$t("dialogs.male"), icon: "male" },
                { gender: this.$t("dialogs.female"), icon: "female" },
                { gender: this.$t("dialogs.transgender"), icon: "transgender" },
            ];
        },
        dialogText() {
            return this.$t('dialogs.filter_select');
        },

        ...mapState({
            tags: (state) => {
                function groupByGroupFormatted(items) {
                    const grouped = items.reduce((accumulator, item) => {
                        if (!accumulator[item.group]) {
                            accumulator[item.group] = {
                                title: item.group,
                                items: [],
                            };
                        }

                        accumulator[item.group].items.push(item);
                        return accumulator;
                    }, {});

                    return Object.values(grouped);
                }

                return groupByGroupFormatted(state.story.tags);
            },
            filters: (state) => state.story.filters,
        }),
    },
    methods: {
        ...mapActions(["getTags"]),
        getFilteredTagsByGender() {
            function hasIntersection(array1, array2) {
                const set1 = new Set(array1);
                return array2.some((element) => set1.has(element));
            }

            let activeGenders = [];
            if (this.model.activeGenders) {
                if (!this.model.activeGenders.length) {
                    activeGenders = ["male", "female", "transgender"];
                } else {
                    activeGenders = this.model.activeGenders;
                }
            }

            let groups = this.tags;
            groups.map((group) => {
                group.items = group.items.filter((tag) => {
                    let allowedGenders = [];
                    if (tag.is_male === 1) {
                        allowedGenders.push("male");
                    }
                    if (tag.is_female === 1) {
                        allowedGenders.push("female");
                    }
                    if (tag.is_transgender === 1) {
                        allowedGenders.push("transgender");
                    }

                    return (
                        !this.model.activeGenders ||
                        hasIntersection(activeGenders, allowedGenders)
                    );
                });

                return group;
            });
            return groups.filter((group) => group.items.length > 0);
        },
        toggleGenders(gender) {
            if (this.model.activeGenders.includes(gender)) {
                const index = this.model.activeGenders.indexOf(gender);
                this.model.activeGenders.splice(index, 1);
            } else {
                this.model.activeGenders.push(gender);
            }
        },
        changePanel() {
            this.toggle = !this.toggle;
        },
        changeCategoriesPanel() {
            this.categoriesToggle = !this.categoriesToggle;
        },
        handleClickSave() {
            var all_section = JSON.parse(JSON.stringify(this.model.sections));
            if(all_section.length != 0){
                this.setFilters(this.model);
                localStorage.setItem("home_filters", JSON.stringify(this.model));
                this.$emit("save", this.model);
            } else {
                this.showErrorDialog = true;
                return;
            }
        },
        handleResetFilter() {
            localStorage.removeItem("home_filters");
            this.model = {
                sections: ["subscribed", "following", "discover"],
                range: [18, 100],
                panel: "",
                categoriesPanel: "",
                tags: [],
                categories: [],
                activeGenders: [],
            };
            // this.model = {...DEFAULT_FILTERS}
            this.setFilters(this.model);
            this.$emit("reset", this.model);
        },

        handleSelectedCategories(selectedIds) {
            this.model.categories = selectedIds
        },

        ...mapMutations(["setFilters"]),
    },
    components: { Checkboxes, SelectGender, FilterHomeBlock, Dialog, InformDialog, UserCategories },
    mounted() {
        const homeFilters = localStorage.getItem("home_filters");
        if (homeFilters) {
            this.setFilters(JSON.parse(homeFilters));
        }
        this.model = this.filters;
        this.getTags();
    },
};
</script>

<style scoped lang="scss">
.SectionControl {
    &-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}
.tags-text {
    font-size: 0.9em;
    height: 32px;
}
.title-filter {
    font-size: 1.4em;
}

.expand-block-header__before-post.v-expansion-panel-header--active {
    background-color: transparent;
    border-radius: 16px 16px 0 0;
}

.expand-block-header__before-post:not(.v-expansion-panel-header--active) {
    background-color: transparent;
    border-radius: 16px;
    transition: 0.7s;
}

.expand-block-body__before-post {
    background-color: transparent;
    border-radius: 0 0 16px 16px;
}

.theme--dark.v-expansion-panels .v-expansion-panel {
    background-color: transparent;
    color: #f5f4e5;
}
</style>
